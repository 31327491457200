import { useEffect, useState } from 'react';

const isBrowser = typeof window !== 'undefined';
export const AnalyticsWrapper = ({ children, location, path }) => {
  const [referrer, setReferrer] = useState(
    isBrowser ? document.referrer : undefined,
  );

  useEffect(() => {
    if (!isBrowser || !path) return;

    if (window?.analytics) {
      window.analytics.page({ referrer });
    }
    if (location) {
      const origin = location.origin;
      const pathname = location.pathname;
      setReferrer(origin + pathname);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [path]);

  return children;
};
